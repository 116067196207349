const initialState = {
  user: {
    id: null,
    email: null,
    name: null,
    forgotPasswordToken: null,
    loginOtp: null,
    isAdmin: false,
    userSurveyId: null,
  },
  accessToken: null,
  isAuthenticated: true,
  friendReqCount: null,
  messageCount: 0,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      // console.log("STATE:", state);
      state.user = action.payload.user;
      state.accessToken = action.payload.access_token;
      state.isAuthenticated = action.payload.access_token ? true : false;
      break;
    case "LOGOUT":
      // console.log("STATE:", state);
      state.user = initialState.user;
      state.accessToken = null;
      state.isAuthenticated = false;
      break;
    case "UPDATE_USER":
      // console.log("USER STATE:", state);
      state.user = action.payload;
      break;
    case "SET_FORGOT_EMAIL_AND_TOKEN":
      // console.log("STATE:", state);
      state.user.forgotPasswordToken = action.payload.forgotPasswordToken;
      state.user.email = action.payload.identity;
      break;
    case "SET_REGISTER_VERIFY_EMAIL":
      state.user.email = action.payload.email;
      break;
    case "SET_USER_SURVEY_ID":
      state.user.userSurveyId = action.payload.id;
      break;
    case "UPDATE_FRIEND_REQ_COUNT":
      state.friendReqCount = action.payload.friendReqCount;
      break;
    case "UPDATE_FRIEND_REQ_COUNT":
      state.friendReqCount = action.payload.friendReqCount;
      break;
    case "UPDATE_MESSAGE_COUNT":
      state.messageCount = action.payload.messageCount;
      break;

    default:
    // console.log("State", state);
  }
  return state;
};
