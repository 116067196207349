import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button, Dropdown, ButtonGroup } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useNavigate } from "react-router-dom";
import {
  logo_gcsrm,
  membership,
  navbar_logo,
  profile_image,
} from "../../assets";
import { MdOutlineDashboard } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  RiUserFill,
  RiSettings5Fill,
  RiLogoutBoxRFill,
  RiMessage2Fill,
} from "react-icons/ri";
import * as authActions from "../../actions/AuthActions";
import { MdPayment } from "react-icons/md";
import { useDebugValue, useEffect, useRef, useState } from "react";
import { notify } from "../../actions/notify";
import { backendURL, truncateString } from "../../utils/utilities";
import { IoClose } from "react-icons/io5";
import { HiUsers, HiUserGroup } from "react-icons/hi2";

const NavBar = ({ containerRef, handleLogoClick }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const token = useSelector((state) => state.authReducer.accessToken);
  const messageCount = useSelector((state) => state.authReducer.messageCount);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef();

  const user = useSelector((state) => state.authReducer.user);

  const handleLogout = () => {
    dispatch(authActions.logout());
    notify("USER LOGGED OUT SUCCESSFULLY", "success");
    navigate("/login");
  };

  const [show, setShow] = useState(false);
  const handleToggle = () => {
    setShow(!show);
  };

  const handleNavigate = (route) => {
    navigate(route);
    setShow(false);
  };

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const fetchCount = async () => {
    const response = await authActions.customAction(
      `users/connection/count`,
      "Requests count",
      "fetch",
      [],
      token
    );
    if (response) {
      console.log(response);
      // dispatch(
      //   authActions.setUnreadMsgCount({
      //     unreadMsgCount: response?.data?.unreadMsgCount,
      //   })
      // );
      // dispatch(
      //   authActions.setRequestCount({
      //     requestCount: response?.data?.receivedConnectionsCount,
      //   })
      // );
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Navbar
      expand="lg"
      className="bg-white flex-row-reverse flex-md-row w-100 top-0 px-4"
      style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)", zIndex: 999 }}
    >
      <Container className="d-flex justify-content-space-between mx-md-4 nav-container">
        <Navbar.Brand href="">
          <img
            style={{ maxHeight: "50px" }}
            className="img-fluid cursor-pointer select-none"
            src={navbar_logo}
            alt=""
            onClick={() => {
              if (
                containerRef?.current?.scrollTop > 1 &&
                window.location.pathname === "/"
              ) {
                // console.log(containerRef.current.scrollTop);
                handleLogoClick();
              } else {
                navigate("/");
              }
            }}
          />
        </Navbar.Brand>
        <div className="d-block d-md-none">
          <Navbar.Offcanvas
            placement="top"
            className="bg-white navbar-main"
            show={screenWidth < 780 && show}
          >
            <Offcanvas.Header style={{ borderBottom: "1px solid #3D3D3D" }}>
              <img
                className=""
                src={logo_gcsrm}
                alt="Logo"
                style={{ height: "60px", width: "60px" }}
              />
              <IoClose
                onClick={() => setShow(false)}
                className="cursor-pointer"
                size={30}
              />
            </Offcanvas.Header>
            <Offcanvas.Body className="h-100">
              <Nav
                className="d-flex flex-column flex-md-row justify-content-end align-items-md-center flex-grow-1 pe-0 navbar-gap"
                style={{ gap: 40 }}
              >
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ gap: 20 }}
                >
                  <Button
                    onClick={handleToggle}
                    variant="link"
                    className="d-flex align-items-center bg-transparent text-black text-decoration-none fs-16 fw-500"
                  >
                    <div className="me-3">Hi, {user.name}</div>
                    {user && (
                      <img
                        onClick={handleToggle}
                        alt=""
                        src={
                          user?.user_profile?.profileImg
                            ? backendURL + user.user_profile?.profileImg
                            : profile_image
                        }
                        className="user-profile select-none cursor-pointer"
                      />
                    )}
                  </Button>
                  <div>
                    <Button
                      onClick={() => handleNavigate("/")}
                      className={"nav-button"}
                    >
                      <MdOutlineDashboard size={24} />{" "}
                      <span className="fw-500">Dashboard</span>
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="nav-button"
                      onClick={() => handleNavigate(`/accounts/${user.id}`)}
                    >
                      <RiUserFill size={18} />
                      <span>My Account</span>
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="nav-button"
                      onClick={() => handleNavigate(`/friends`)}
                    >
                      <HiUsers size={18} />
                      <span>Friends</span>
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="nav-button"
                      onClick={() => handleNavigate(`/messages`)}
                    >
                      <RiMessage2Fill size={18} />
                      <span>Messages</span>
                      <span
                        className="ms-2 text-white d-flex justify-content-center align-items-center fs-12"
                        style={{
                          top: "-8px",
                          right: "-5px",
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          background: "#D7A023",
                        }}
                      >
                        {messageCount}
                      </span>
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="nav-button"
                      onClick={() => handleNavigate(`/groups`)}
                    >
                      <HiUserGroup size={18} />
                      <span>Groups</span>
                    </Button>
                  </div>
                  {user?.loginType !== "Google" &&
                    user?.loginType !== "Facebook" && (
                      <div>
                        <Button
                          className="nav-button"
                          onClick={() => handleNavigate("/settings")}
                        >
                          <RiSettings5Fill size={18} /> Settings
                        </Button>
                      </div>
                    )}
                  <div>
                    <Button
                      className="nav-button"
                      onClick={() => handleLogout()}
                    >
                      <RiLogoutBoxRFill size={18} /> Logout
                    </Button>
                  </div>
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </div>

        {screenWidth > 780 && (
          <div className="d-none d-md-block">
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="top"
              className="bg-white navbar-main"
            >
              <Offcanvas.Header
                closeButton
                style={{ borderBottom: "1px solid #3D3D3D" }}
              >
                <img
                  className=""
                  src={logo_gcsrm}
                  alt="Logo"
                  style={{ height: "60px", width: "60px" }}
                />
              </Offcanvas.Header>
              <Offcanvas.Body className="h-100">
                <Nav
                  className="d-flex flex-column flex-md-row justify-content-end align-items-md-center flex-grow-1 pe-0 navbar-gap"
                  style={{ gap: 40 }}
                >
                  <Button
                    onClick={() => handleNavigate("/")}
                    className={"nav-button d-none d-md-block"}
                  >
                    <MdOutlineDashboard size={24} />{" "}
                    <span className="fw-500">Dashboard</span>
                  </Button>
                  {/* <Button
                onClick={() => handleNavigate("/membership")}
                className={"nav-button d-flex align-items-center  "}
              >
                <img src={membership} alt="membership" className="me-2" />
                <span className="fw-500">Membership</span>
              </Button> */}
                  {/* <Button
                onClick={() => console.log("notify")}
                className={"nav-button"}
              >
                <IoIosNotifications size={30} />
              </Button> */}
                  <div className="d-none d-md-flex align-items-center">
                    <Dropdown
                      as={ButtonGroup}
                      className="d-flex align-items-center focus-none br-10 "
                      show={show}
                      ref={dropdownRef}
                    >
                      <Button
                        onClick={handleToggle}
                        variant="link"
                        className="bg-transparent text-black text-decoration-none fs-16 fw-500"
                      >
                        Hi, {truncateString(user.name, 50)}
                      </Button>
                      <Dropdown.Toggle
                        onClick={handleToggle}
                        split
                        variant="link"
                        id="dropdown-split-basic"
                        className="bg-transparent text-black focus-none border-0"
                        style={{ boxShadow: "none" }}
                      />
                      <Dropdown.Menu className="focus-none border-0 card-box-shadow">
                        <Dropdown.Item
                          className="fs-15 fw-400 my-1"
                          onClick={() => handleNavigate(`/accounts/${user.id}`)}
                        >
                          <RiUserFill size={18} /> My Account
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="fs-15 fw-400 my-1"
                          onClick={() => handleNavigate(`/friends`)}
                        >
                          <HiUsers size={18} /> Friends
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="d-flex align-items-center fs-15 fw-400 my-1 gap-1  "
                          onClick={() => handleNavigate(`/messages`)}
                        >
                          <RiMessage2Fill size={18} /> Messages
                          <Button
                            className="ms-2 bg-primary text-white d-flex justify-content-center align-items-center fs-12"
                            style={{
                              top: "-8px",
                              right: "-5px",
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                            }}
                          >
                            {messageCount}
                          </Button>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="fs-15 fw-400 my-1"
                          onClick={() => handleNavigate(`/groups`)}
                        >
                          <HiUserGroup size={18} /> Groups
                        </Dropdown.Item>
                        {user?.loginType !== "Google" &&
                          user?.loginType !== "Facebook" && (
                            <Dropdown.Item
                              className="fs-15 fw-400 my-1"
                              onClick={() => handleNavigate("/settings")}
                            >
                              <RiSettings5Fill size={18} /> Settings
                            </Dropdown.Item>
                          )}
                        {/* <Dropdown.Item
                      className="fs-15 fw-400 my-1"
                      onClick={() => handleNavigate("/payments")}
                    >
                      <MdPayment size={18} /> Payment history
                    </Dropdown.Item> */}
                        <Dropdown.Item
                          className="fs-15 fw-400 my-1"
                          onClick={() => handleLogout()}
                        >
                          <RiLogoutBoxRFill size={18} /> Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div>
                      {user && (
                        <img
                          onClick={handleToggle}
                          alt=""
                          src={
                            user?.user_profile?.profileImg
                              ? backendURL + user.user_profile?.profileImg
                              : profile_image
                          }
                          className="user-profile select-none cursor-pointer"
                        />
                      )}
                    </div>
                  </div>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </div>
        )}
        <div className="d-block d-md-none">
          <Navbar.Toggle
            onClick={() => setShow(true)}
            className="d-block d-md-block border-0 focus-none"
            aria-controls="basic-navbar-nav"
          />
        </div>
      </Container>
    </Navbar>
  );
};

export default NavBar;
