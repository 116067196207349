import React from "react";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <div
      className="footer w-100 border"
      style={{
        position: "fixed",
        bottom: 0,
        background: "#F6F6F6",
        zIndex: 999,
      }}
    >
      <div className="container my-2">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <div className="p-2">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="mx-2 text-black" size={20} />
            </a>
            <a
              className="mx-4"
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="mx-2 text-black" size={20} />
            </a>
          </div>
          <div className="fs-12 p-2 text-black">
            Copyright &copy; {new Date().getFullYear()} GCSRM All Rights
            Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
