import {
  questionnaire,
  leaderboard,
  training_resources,
  publication,
} from "../assets";
import { FaFilePdf } from "react-icons/fa";
import { SiGoogledocs } from "react-icons/si";
import { RiFile2Line } from "react-icons/ri";

export const validatePassword = (password) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/;
  return regex.test(password);
};

export const formatDateTime = (dateTimeString) => {
  const originalDate = new Date(dateTimeString);
  const dateOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };

  const formattedDate = originalDate.toLocaleString("en-IN", dateOptions);
  const formattedTime = originalDate.toLocaleString("en-US", timeOptions);
  return `${formattedDate.split("-").join(" ")} | ${formattedTime}`;
};

// export const backendURL = process.env.REACT_APP_BACKEND_URL;
export const backendURL = "https://gcrsbackend.dignitech.com/";

export const dashboardLinks = [
  { img: questionnaire, title: "Questionnaire", route: "/questionnaire" },
  { img: leaderboard, title: "Leaderboard", route: "/leaderboard" },
  {
    img: training_resources,
    title: "Training Resources",
    route: "/training_resources",
  },
  { img: publication, title: "Publication", route: "/publications" },
];

export const isValidURL = (str) => {
  try {
    const url = new URL(str.startsWith("www.") ? `http://${str}` : str);

    return (
      url.protocol === "http:" ||
      (url.protocol === "https:" && url.hostname !== "")
    );
  } catch (error) {
    return false;
  }
};

export const isImageUrl = (url) => {
  return url.match(/\.(jpeg|jpg|gif|png|webp)$/) != null;
};

export const messageIsFile = (file) => {
  if (file) {
    const fileExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "tiff",
      "svg",
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "txt",
      "mp4",
      "avi",
      "mov",
      "wmv",
      "flv",
      "mkv",
      "webm",
    ];
    const extension = file?.split(".").pop().toLowerCase();
    return fileExtensions.includes(extension);
  }
};

export const truncateString = (t, size = 20) => {
  if (t) {
    return t.length > size ? t.substring(0, size) + "..." : t;
  }
  return "--";
};

export const getRelativeMessageTime = (updatedAt) => {
  if (updatedAt) {
    const now = new Date();
    const updatedDate = new Date(updatedAt);
    const diffInSeconds = Math.floor((now - updatedDate) / 1000);

    if (diffInSeconds < 60) {
      return `${diffInSeconds} sec`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} min`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} h`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day`;
    }
  }
};

export const formatMessagesTime = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const isImage = (file) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "svg"];
  const extension = file?.split(".").pop().toLowerCase();
  return imageExtensions.includes(extension);
};

export const isVideo = (file) => {
  const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv", "mkv", "webm"];
  const extension = file?.split(".").pop().toLowerCase();
  return videoExtensions.includes(extension);
};

export const isPdf = (file) => {
  const pdfExtension = "pdf";
  const extension = file?.split(".").pop().toLowerCase();
  return extension === pdfExtension;
};

export const isDocx = (file) => {
  const docExtension = "docx";
  const extension = file?.split(".").pop().toLowerCase();
  return extension === docExtension;
};

const openFile = (fileUrl) => {
  window.open(backendURL + "/" + fileUrl, "_blank");
};

export const FileIcon = ({ fileUrl, type }) => {
  if (isPdf(fileUrl)) {
    return (
      <FaFilePdf
        size={25}
        className={type !== "chat" && "cursor-pointer"}
        onClick={() => type != "chat" && openFile(fileUrl)}
      />
    );
  } else if (isDocx(fileUrl)) {
    return (
      <SiGoogledocs
        size={25}
        className={type !== "chat" && "cursor-pointer"}
        onClick={() => type != "chat" && openFile(fileUrl)}
      />
    );
  } else {
    return (
      <RiFile2Line
        size={25}
        className={type !== "chat" && "cursor-pointer"}
        onClick={() => type != "chat" && openFile(fileUrl)}
      />
    );
  }
};

export const ChatFileIcon = ({ file }) => {
  return (
    <>
      {file.type.startsWith("image/") ? (
        <img
          src={URL.createObjectURL(file)}
          alt="Selected"
          style={{
            width: "25px",
            height: "25px",
            objectFit: "cover",
            borderRadius: "3px",
          }}
        />
      ) : file.type.startsWith("video/") ? (
        <video
          src={URL.createObjectURL(file)}
          style={{
            width: "25px",
            height: "25px",
            objectFit: "cover",
            borderRadius: "3px",
          }}
        />
      ) : file.type === "application/pdf" ? (
        <FaFilePdf size={25} />
      ) : file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
        <SiGoogledocs size={25} />
      ) : (
        <RiFile2Line size={25} />
      )}
    </>
  );
};
