import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import FallbackLoading from "../Loaders/FallbackLoading";

const ScrollToTop = () => {
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 600);
    window.scrollTo(0, 0);
    setLoading(true);
  }, [location?.pathname]);

  if (loading) {
    return <FallbackLoading />;
  }
  return null;
};

export default ScrollToTop;
