import axios from "axios";
import { notify } from "./notify";
import { persistor } from "../store/index";
const backendURL = "https://gcrsbackend.dignitech.com/";

////////////////////////AUTH - Action Creator //////////////////////////

export const login = (data) => {
  return {
    type: "LOGIN",
    payload: data,
  };
};

export const logout = () => {
  return {
    type: "LOGOUT",
    payload: null,
  };
};

export const userUpdate = (data) => {
  return {
    type: "UPDATE_USER",
    payload: data,
  };
};

export const setForgotEmailAndToken = (data) => {
  return {
    type: "SET_FORGOT_EMAIL_AND_TOKEN",
    payload: data,
  };
};

export const setUserSurveyId = (data) => {
  return {
    type: "SET_USER_SURVEY_ID",
    payload: data,
  };
};

export const setRegisterVerifyEmail = (data) => {
  return {
    type: "SET_REGISTER_VERIFY_EMAIL",
    payload: data,
  };
};

export const updateFriendReqCount = (data) => {
  return {
    type: "UPDATE_FRIEND_REQ_COUNT",
    payload: data,
  };
};

export const updateMessageCount = (data) => {
  return {
    type: "UPDATE_MESSAGE_COUNT",
    payload: data,
  };
};

//////////////////////// AUth - Actions //////////////////////////

export const loggingIn = async (data) => {
  try {
    const response = await axios.post(`${backendURL}users/login`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response?.data?.success) {
      notify(response.data.message, "success");
      return response.data;
    } else {
      notify(
        Array.isArray(response?.data?.message)
          ? response?.data?.message[0]
          : response?.data?.message
      );
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      notify(
        Array.isArray(err.response?.data?.message)
          ? err.response?.data?.message[0]
          : err.response?.data?.message
      );
      return err.response;
    } else {
      notify(err.message);
    }
  }
};

export const signup = async (data) => {
  try {
    const response = await axios.post(`${backendURL}users/register`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.success) {
      notify(response.data.message, "success");
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      notify(
        Array.isArray(err.response?.data?.message)
          ? err.response?.data?.message[0]
          : err.response?.data?.message
      );
    } else {
      notify(err.message);
    }
  }
};

export const sendOtp = async (url, data) => {
  try {
    const response = await axios.post(`${backendURL}${url}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response sendotp---------", response);
    if (response.data.success) {
      notify(response.data.message, "success");
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      notify(
        Array.isArray(err.response?.data?.message)
          ? err.response?.data?.message[0]
          : err.response?.data?.message
      );
    } else {
      notify(err.message);
    }
  }
};

export const verify = async (url, data) => {
  try {
    const response = await axios.post(`${backendURL}${url}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response verify---------", response);
    if (response.data.success) {
      notify(response.data.message, "success");
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      notify(
        Array.isArray(err.response?.data?.message)
          ? err.response?.data?.message[0]
          : err.response?.data?.message
      );
    } else {
      notify(err.message);
    }
  }
};

export const resetPassword = async (url, data) => {
  try {
    const response = await axios.post(`${backendURL}${url}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Response resetPassword---------", response);
    if (response.data.success) {
      notify(response.data.message, "success");
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      notify(
        Array.isArray(err.response?.data?.message)
          ? err.response?.data?.message[0]
          : err.response?.data?.message
      );
    } else {
      notify(err.message);
    }
  }
};

//////////////////////// User - Actions //////////////////////////

export const changePassword = async (data, token) => {
  try {
    const response = await axios.patch(
      `${backendURL}users/change/password`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response) {
      if (response.data.success) {
        notify(response.data.message, "success");
      } else {
        notify(response.data.message);
      }
      return response.data;
    } else {
      // console.log("Unexpected status code:", response.status);
    }
  } catch (err) {
    if (err.response) {
      notify(
        Array.isArray(err.response?.data?.message)
          ? err.response?.data?.message[0]
          : err.response?.data?.message
      );
    } else {
      notify(err.message);
    }
    // console.error("Error in updating user:", err);
  }
};

//////////////////////// -------- Custom Actions --------- //////////////////////////

export const customAction = async (
  url,
  title,
  mode,
  data,
  token,
  multipart = false,
  showNotify = true
) => {
  switch (mode) {
    case "post":
      try {
        const response = await axios.post(`${backendURL}${url}`, data, {
          headers: {
            "Content-Type": multipart
              ? "multipart/form-data"
              : "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(`${title} Response", ${response}`);
        if (response) {
          if (response.data.success) {
            showNotify && notify(response.data.message, "success");
          } else {
            showNotify && notify(response.data.message);
          }
          return response.data;
        }
      } catch (err) {
        if (err.response) {
          showNotify &&
            notify(
              Array.isArray(err.response?.data?.message)
                ? err.response?.data?.message[0]
                : err.response?.data?.message
            );
        } else {
          showNotify && notify(err.message);
        }
        return null;
      }
      break;
    case "patch":
      try {
        const response = await axios.patch(`${backendURL}${url}`, data, {
          headers: {
            "Content-Type": multipart
              ? "multipart/form-data"
              : "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(`${title} Response", ${response}`);
        if (response) {
          if (response.data.success) {
            notify(response.data.message, "success");
          } else {
            notify(response.data.message);
          }
          return response.data;
        }
      } catch (err) {
        // console.log("Error", err);
        if (err.response) {
          notify(
            Array.isArray(err.response?.data?.message)
              ? err.response?.data?.message[0]
              : err.response?.data?.message
          );
        } else {
          notify(err.message);
        }
      }
      break;
    case "delete":
      try {
        const response = await axios.delete(`${backendURL}${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(`${title} Response", ${response}`);
        if (response.data.success) {
          notify(response.data.message, "success");
          return response.data;
        } else {
          notify(response.data.message);
          // return response;
        }
        // return response;
      } catch (err) {
        // console.log("Error", err);
        if (err.response) {
          notify(
            Array.isArray(err.response?.data?.message)
              ? err.response?.data?.message[0]
              : err.response?.data?.message
          );
        } else {
          notify(err.message);
        }
      }
      break;
    case "fetch":
      try {
        const response = await axios.get(`${backendURL}${url}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(`${title} Response", ${response}`);
        if (response.data) {
          return response.data;
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          await persistor.purge();
          localStorage.clear();
          window.location.href = "/";
        }
      }
      break;
    default:
      break;
  }
};
