import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux-immer";
import { produce } from "immer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk from "redux-thunk";
import { authReducer } from "../reducers/AuthReducer";

const persistConfig = {
  key: ["authReducer"],
  storage: storage,
  whitelist: ["authReducer"],
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers(produce, {
  authReducer: authReducer,
});

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: pReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});

export const persistor = persistStore(store);
