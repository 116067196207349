import { PulseLoader } from "react-spinners";

const FallbackLoading = ({ height }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: height ? height : "100vh" }}
    >
      <PulseLoader color="#D7A023" />
    </div>
  );
};

export default FallbackLoading;
