import { toast } from "react-toastify";

export const notify = (msg, type) => {
  switch (type) {
    case "success":
      toast.success(msg);
      break;
    case "error":
      toast.error(msg);
      break;
    case "warning":
      toast.warning(msg);
      break;
    default:
      toast.error(msg);
  }
};

export const toastLoading = (message) => {
  const id = toast.loading(message);
  return id;
};

export const updateLoadingToast = (id, message, type) => {
  toast.update(id, {
    render: message,
    type: type || "success",
    isLoading: false,
    autoClose: 1000,
  });
};
