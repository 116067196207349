import React, { Suspense, lazy, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import FallbackLoading from "./components/Loaders/FallbackLoading";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
const SignUp = React.lazy(() => import("./pages/Signup/Signup"));
const Login = React.lazy(() => import("./pages/Login/Login"));
const NotFound = React.lazy(() => import("./pages/NotFound/NotFound"));
const Setup = React.lazy(() => import("./pages/Setup/Setup"));
const ForgotPassword = React.lazy(() =>
  import("./pages/ForgotPassword/ForgotPassword")
);
const Membership = React.lazy(() => import("./pages/Membership/Membership"));
const Questionnaire = React.lazy(() =>
  import("./pages/Questionnaire/Questionnaire")
);
const QuestionnaireList = React.lazy(() =>
  import("./pages/Questionnaire/QuestionnaireList")
);
const TrainingResources = React.lazy(() =>
  import("./pages/TrainingResources/TrainingResources")
);
const TrainingResource = React.lazy(() =>
  import("./pages/TrainingResources/TrainingResource")
);
const Publications = React.lazy(() =>
  import("./pages/Publications/Publications")
);
const Publication = React.lazy(() =>
  import("./pages/Publications/Publication")
);
const Leaderboard = React.lazy(() => import("./pages/Leaderboard/Leaderboard"));
const PaymentHistory = React.lazy(() =>
  import("./pages/PaymentHistory/PaymentHistory")
);
const Account = React.lazy(() => import("./pages/Account/Account"));
const Accounts = React.lazy(() => import("./pages/Account/Accounts"));
const EditProfile = React.lazy(() => import("./pages/Account/EditProfile"));
const Posts = React.lazy(() => import("./pages/Posts/Posts"));
const UserPosts = React.lazy(() => import("./pages/Posts/UserPosts"));
const Friends = React.lazy(() => import("./pages/Friends/Friends"));
const Groups = React.lazy(() => import("./pages/Groups/Groups"));
const CreateGroup = React.lazy(() => import("./pages/Groups/CreateGroup"));
const ViewGroup = React.lazy(() => import("./pages/Groups/ViewGroup"));
const Messages = React.lazy(() => import("./pages/Messages/Messages"));
// import Dashboard from "./pages/Dashboard/Dashboard";
const Dashboard = lazy(() => import(`./pages/Dashboard/Dashboard`));

const Routers = () => {
  const isAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  const containerRef = useRef(null);

  const handleLogoClick = () => {
    const container = containerRef.current;
    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Suspense fallback={<FallbackLoading />}>
        <Router>
          {isAuthenticated && (
            <NavBar
              containerRef={containerRef}
              handleLogoClick={handleLogoClick}
            />
          )}
          <ScrollToTop />
          <Routes>
            {!isAuthenticated && (
              <>
                <Route
                  path="login"
                  element={<Login isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="signup"
                  element={<SignUp isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path="forgot"
                  element={<ForgotPassword type="forgot" />}
                />
                <Route
                  path="verify"
                  element={<ForgotPassword type={"verify"} />}
                />
                <Route
                  path="verify-register"
                  element={<ForgotPassword type={"verify-register"} />}
                />
                <Route
                  path="reset"
                  element={<ForgotPassword type={"reset"} />}
                />
              </>
            )}
            <Route
              path=""
              element={<Dashboard containerRef={containerRef} />}
            />
            {isAuthenticated && (
              <>
                <Route path="setup" element={<Setup />} />
                <Route path="questionnaire" element={<QuestionnaireList />}>
                  <Route path=":id" element={<Questionnaire />} />
                </Route>
                <Route
                  path="training_resources"
                  element={<TrainingResources />}
                >
                  <Route path=":id" element={<TrainingResource />} />
                </Route>

                <Route path="publications" element={<Publications />}>
                  <Route path=":id" element={<Publication />} />
                </Route>

                <Route path="leaderboard" element={<Leaderboard />}>
                  <Route path=":id" element={<Publication />} />
                </Route>

                <Route path="accounts" element={<Accounts />}>
                  <Route path=":id" element={<Account />} />
                </Route>
                <Route
                  path="/accounts/:id/friends"
                  element={<Friends type={"user-friends"} />}
                />

                <Route path="posts" element={<Posts />}>
                  <Route path=":id" element={<UserPosts />} />
                </Route>
                <Route path="friends" element={<Friends />}>
                  <Route path=":id" element={<UserPosts />} />
                </Route>
                <Route
                  path="friend-requests"
                  element={<Friends type={"friend-requests"} />}
                >
                  <Route path=":id" element={<UserPosts />} />
                </Route>
                <Route
                  path="find-friends"
                  element={<Friends type={"find-friends"} />}
                >
                  <Route path=":id" element={<UserPosts />} />
                </Route>

                <Route path="groups" element={<Groups type="groups" />}>
                  <Route path=":id" element={<ViewGroup />}></Route>
                </Route>
                <Route
                  path="/groups/:id/posts"
                  element={<UserPosts postType={"group-posts"} />}
                />
                <Route
                  path="/groups/:id/members"
                  element={<Friends type={"group-members"} />}
                />
                <Route
                  path="/groups/:id/requests"
                  element={<Friends type={"group-requests"} />}
                />
                <Route
                  path="find-groups"
                  element={<Groups type={"find-groups"} />}
                >
                  {/* <Route path=":id" element={<UserPosts />} /> */}
                </Route>
                <Route
                  path="group-invitations"
                  element={<Groups type={"group-invitations"} />}
                >
                  {/* <Route path=":id" element={<UserPosts />} /> */}
                </Route>
                <Route path="create-group" element={<CreateGroup />}>
                  <Route path=":id" element={<CreateGroup />} />
                </Route>

                {/* <Route path="payments" element={<PaymentHistory />} />s */}

                <Route
                  path="settings"
                  element={<ForgotPassword type="changepassword" />}
                />
                <Route path="messages" element={<Messages />} />
                <Route path="editprofile" element={<EditProfile />} />
              </>
            )}

            <Route path="*" element={<NotFound />} />
          </Routes>
          {isAuthenticated && <Footer />}
        </Router>
      </Suspense>
    </>
  );
};

export default Routers;
