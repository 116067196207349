import "./App.css";
import Routers from "./routers";
import "./App.scss";
import { Provider } from "react-redux";
import { store } from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  return (
    <GoogleOAuthProvider clientId="1070438951881-4ajrmhmba1n0gh190gfbb99f14ar2aft.apps.googleusercontent.com">
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
        <Routers />
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
